import * as React from "react"
import { Link } from "gatsby"
import "@fontsource/open-sans"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  let header = (
    <>
      <Link className="header-link-home" to="/">
        {title}
      </Link>
      <Link style={{ marginLeft: "1.5rem" }} to="/blog">
        Blog
      </Link>
    </>
  )

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Crafted with 💖
        {` `}
        By <a href="https://github.com/hspotlight">HSpotlight</a>
      </footer>
    </div>
  )
}

export default Layout
